import React from 'react';
import './StorageUtil';
import {getUser, removeData, saveUserSession} from './StorageUtil';
import Global from './Global';
import ToastServive from 'react-material-toast';
import SockJsClient from 'react-stomp';

const AuthContext = React.createContext()

export const toast = ToastServive.new({
    place:'topRight', //topLeft topRight bottomLeft bottomRight
    duration:4,
    maxCount:8,
    closable:true
});


//https://medium.freecodecamp.org/how-to-protect-your-routes-with-react-context-717670c4713a
class AuthProvider extends React.Component {
    state = {
        isAuth: getUser()!=null,
        user: getUser()
    }

    constructor() {
        super()
        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    login(user) {
        // alert(JSON.stringify(user))
        saveUserSession(user, true)
        this.setState({
            user : getUser(),
            isAuth:getUser()!=null
        }, () => {
            // setTimeout(() => this.setState({ isAuth: true }), 1000)
        })
    }

    logout() {
        // removeStorage()
        removeData(Global.USER)
        removeData(Global.AUTHORIZATION)
        this.setState({
            isAuth: getUser()!=null,
            user: null
        })
    }

    refresh(){
        this.setState({
            isAuth: getUser()!=null,
            user:getUser()
        }, () => {
            this.forceUpdate()
        })
    }

    render() {
        return (
            <AuthContext.Provider
                value={{
                    isAuth: this.state.isAuth,
                    user: this.state.user,
                    login: this.login,
                    logout: this.logout,
                    refresh:this.refresh,
                }}>
                {this.props.children}
                {
                    this.state.isAuth&&(
                        <SockJsClient
                            url={Global.BASE_URL}
                            topics={[Global.API.LISTEN_TRANSACTION]}
                            onMessage={(message) => {
                                toast.info(message)
                            }}
                            getRetryInterval={()=>10000}
                            autoReconnect={false}
                            heartbeat={60000}
                            ref={ (client) => { this.clientRef = client }} />
                    )
                }
            </AuthContext.Provider>
        )
    }
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
