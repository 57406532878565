/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.19.577 on 2025-01-09 05:37:06.

export class EBaseDto implements Serializable, Comparable<EBaseDto> {
    id: number;
    created: Date;
    createdBy: number;
    updated: Date;
    updatedBy: number;
    active: boolean;
}

export class WSResponse<D, E> {
    code: number;
    message: string;
    messageError: E;
    data: D;
    totalElement: number;
    totalPage: number;
    pageElement: number;
    success: boolean;
}

export class ComponentDto extends EBaseDto {
    label: string;
    description: string;
    value: string;
}

export class FormApplicationDto extends EBaseDto {
    formLaneApplication: FormLaneApplicationDto;
    subFormApplications: SubFormApplicationDto[];
    valueId: number;
    form: Form;
    value: string;
    tag: string;
    imageUrl: string;
}

export class FormLaneApplicationDto extends EBaseDto {
    title: string;
    laneId: number;
    formLane: FormLane;
    formApplications: FormApplicationDto[];
}

export class FormTransitDto extends EBaseDto {
    cookieValue: string;
    model: ModelDto;
    user: UserDto;
    submitted: boolean;
}

export class InspectionDto extends EBaseDto {
    serialCode: string;
    customerOrderNo: string;
    client: string;
    productSKU: string;
    productName: string;
    controller: string;
    fwVersion: string;
    user: UserDto;
    admin: AdminDto;
    model: ModelDto;
    inspectionStatus: InspectionStatusDto;
    reference: string;
    performerName: string;
    performerRole: string;
    performerImageUrl: string;
    groupFormName: string;
    inspectionEventSize: number;
    viewReference: string;
    currentInspectionEvent: InspectionEventDto;
    serialNumbers: SerialNumberDto[];
    printedCount: number;
    serialComponents: SerialComponentDto[];
    serialComponent: SerialComponentDto;
    attachedModel: ModelDto;
}

export class InspectionEventDto extends EBaseDto {
    serialCode: string;
    customerOrderNo: string;
    client: string;
    productSKU: string;
    productName: string;
    controller: string;
    fwVersion: string;
    inspection: InspectionDto;
    user: UserDto;
    admin: AdminDto;
    inspectionStatus: InspectionStatusDto;
    inspectionNote: string;
    reference: string;
    groupFormName: string;
    viewReference: string;
    performerName: string;
    performerRole: string;
    performerImageUrl: string;
    formLaneApplications: FormLaneApplicationDto[];
    rectifierControllerSerialNumbers: RectifierControllerSerialNumberDto[];
    canContinue: boolean;
    canCancel: boolean;
    canClose: boolean;
    canReprint: boolean;
    canSuspend: boolean;
    canReopen: boolean;
}

export class InspectionStatusDto extends EBaseDto {
    name: string;
    label: string;
    description: string;
    sortir: number;
}

export class MainModelDto extends EBaseDto {
    label: string;
    sortir: number;
    model: ModelDto;
}

export class ModelDto extends EBaseDto {
    systemType: string;
    modelName: string;
    partName: string;
    pn: string;
    acInput: string;
    frequency: string;
    acInputCurrent: string;
    dcOutput: string;
    polarity: string;
    warranty: number;
    name: string;
    description: string;
    slug: string;
    autoSlug: boolean;
    published: boolean;
    periodType: string;
    scope: string;
    referenceDocument: string;
    testingEquipmentAndAccessories: string;
    startPeriod: Date;
    endPeriod: Date;
    inspectionLimitType: string;
    inspectionLimitCount: number;
    inspectionCount: number;
    iconPhoto: string;
    iconPhotoUrl: string;
    callbackId: string;
    modelCategory: ModelCategoryDto;
    groupForm: GroupFormDto;
    suggestion: number;
    type: string;
    applyable: boolean;
    message: string;
    completeUrl: string;
    shareableAddress: string;
    inspectionLimitPerUser: number;
    sizeOfRectifierControllerSerialNumber: number;
    createPerformBy: AdminDto;
    updatePerformBy: AdminDto;
    situationInfo: string;
    periodTypeLabel: string;
    inspectionLimitTypeLabel: string;
    inspectionLimitPerUserLabel: string;
    systemSpecifications: SystemSpecificationDto[];
    components: ComponentDto[];
}

export class PVInspectionStatusDto extends EBaseDto {
    inspection: InspectionDto;
    inspectionStatus: InspectionStatusDto;
    note: string;
}

export class RectifierControllerSerialNumberDto extends EBaseDto {
    indexOrder: number;
    serialCode: string;
    inspectionEvent: InspectionEventDto;
}

export class SerialComponentDto extends EBaseDto {
    inspection: InspectionDto;
    components: ComponentDto[];
}

export class SerialNumberDto extends EBaseDto {
    code: string;
    inspection: InspectionDto;
    serialNumberPrints: SerialNumberPrintDto[];
    printedCount: number;
}

export class SerialNumberPrintDto extends EBaseDto {
    serialNumber: SerialNumberDto;
    user: UserDto;
    token: string;
}

export class SubFormApplicationDto extends EBaseDto {
    formApplication: FormApplicationDto;
    valueId: number;
    form: Form;
    value: string;
    tag: string;
    imageUrl: string;
}

export class SystemSpecificationDto extends EBaseDto {
    label: string;
    description: string;
}

export class AdminDashboard extends EBaseDto {
    totalInspection: number;
    thisMonthInspection: number;
    previousMonthInspection: number;
    thisMonthInspectionPercent: number;
}

export class BarInfo {
    id: number;
    label: string;
    startDate: Date;
    endDate: Date;
}

export class DashboardCategoryAnalytic {
    barInfos: BarInfo[];
    datasets: DataSet<number>[];
    allUserIncomeAmount: number;
    allUserWithdrawAmount: number;
}

export class DataSet<OBJECT> {
    label: string;
    backgroundColor: string;
    borderColor: string;
    stack: string;
    data: OBJECT[];
}

export class UserDashboard extends EBaseDto {
    totalInspection: number;
    thisMonthInspection: number;
    previousMonthInspection: number;
    thisMonthInspectionPercent: number;
}

export class AttachmentDto extends EBaseDto {
    filename: string;
    fileLink: string;
}

export class SendEmailDto extends EBaseDto {
    subject: string;
    content: string;
    allCustomer: boolean;
    allSubscriber: boolean;
    sendStatus: string;
    sendDirectly: boolean;
    sendAt: Date;
    regularSender: boolean;
    sender: string;
    customerEmails: SendEmailPreference[];
    subscriberEmails: SendEmailPreference[];
    attachments: AttachmentDto[];
    contentType: boolean;
}

export class SendEmailPreference implements Serializable {
    email: string;
    responseStatus: string;
}

export class Form extends EBaseDto implements Serializable {
    tag: string;
    title: string;
    positiveValue: string;
    negativeValue: string;
    placeHolder: string;
    type: string;
    typeId: number;
    mandatory: boolean;
    hidePrintOut: boolean;
    allowedCharacters: string;
    unallowedCharacters: string;
    maxLength: number;
    line: number;
    fetchApi: string;
    queryParams: QueryParam[];
    dependAnotherTag: boolean;
    subInputs: Form[];
}

export class FormLane extends EBaseDto implements Serializable {
    title: string;
    description: string;
    cards: Form[];
}

export class GroupFormDto extends EBaseDto {
    name: string;
    documentNumber: string;
    formLanes: FormLane[];
}

export class QueryParam implements Serializable {
    key: string;
    reference: string;
}

export class PushNotificationResponse {
    status: number;
    message: string;
}

export class ApplicationVersionDto extends EBaseDto {
    androidVersionCode: number;
    androidVersionName: string;
    forceToUpdate: boolean;
    title: string;
    description: string;
    slug: string;
    completeUrl: string;
}

export class ConfigurationAdminDto extends EBaseDto {
    host: string;
    port: number;
    username: string;
    password: string;
    mailFrom: string;
    replyTo: string;
    smtpAuth: boolean;
    smtpStarttlsEnable: boolean;
    smtpStarttlsRequired: boolean;
    mailDebug: boolean;
    sequenceStartSerialNumber: number;
}

export class ConfigurationDto extends EBaseDto {
    workingAgreementFileUrl: string;
    name: string;
    companyName: string;
    companyDescription: string;
    companyAddress: string;
    companyVillage: VillageDto;
    companyPhoneNumber: string;
    companyFaximile: string;
    contactPersonPhoneNumber: string;
    contactWhatsapp: string;
    pageRowSize: number;
    hashtag: string;
    instagramLink: string;
    facebookLink: string;
    twitterLink: string;
    minWithdrawal: number;
    facebookAppId: string;
    googleClientId: string;
    baseApi: string;
    bannerWidth: number;
    bannerHeight: number;
    mobileBannerWidth: number;
    mobileBannerHeight: number;
    regularEmailSender: string;
    mailDomain: string;
    modelBannerWidth: number;
    modelBannerHeight: number;
}

export class DataPage<O> {
    datas: O[];
    pageElement: number;
    totalPage: number;
    totalElement: number;
}

export class HardDiskDto extends EBaseDto {
    path: string;
    totalSpace: number;
    totalUsableSpace: number;
    totalFreeSpace: number;
}

export class ModelCategoryDto extends EBaseDto {
    sortir: number;
    name: string;
    icon: string;
    hexaColor: string;
    topic: string;
    count: number;
    totalModel: number;
    totalAvailableModel: number;
    iconUrl: string;
    coloredIconUrl: string;
}

export class NotificationData implements Serializable {
    notificationId: string;
    referenceId: string;
    referenceClass: string;
    addressLink: string;
}

export class NotificationDto extends EBaseDto implements Serializable {
    title: string;
    message: string;
    topic: string;
    type: string;
    data: NotificationData;
    admin: AdminDto;
    image: string;
    imageLink: string;
}

export class NotificationUserDto extends EBaseDto implements Serializable {
    user: UserDto;
    notification: NotificationDto;
    userDevice: UserDeviceDto;
    read: boolean;
}

export class ResultSendMailDto {
    result: boolean;
    message: string;
}

export class BankAccountDto extends EBaseDto {
    bank: BankDto;
    customerName: string;
    accountCode: string;
}

export class Option extends EBaseDto {
    name: string;
}

export class BankDto extends Option {
    label: string;
    code: string;
    image: string;
    imageLink: string;
}

export class CityDto extends Option {
    platNumber: string;
    province: ProvinceDto;
    courierId: string;
}

export class DistrictDto extends Option {
    city: CityDto;
    courierId: string;
}

export class ProvinceDto extends Option {
    countryCode: string;
    courierId: string;
}

export class VillageDto extends Option {
    postalCode: string;
    district: DistrictDto;
}

export class MenuDto extends EBaseDto {
    name: string;
    clazz: string;
    group: string;
    link: string;
}

export class RoleDto extends EBaseDto {
    name: string;
}

export class RoleMenuDto extends EBaseDto {
    role: RoleDto;
    menu: MenuDto;
}

export class AdminDeviceDto extends EBaseDto {
    fcmToken: string;
    platform: string;
    userAgent: string;
    ipAddress: string;
    deviceId: string;
    latitude: number;
    longitude: number;
    lastOpenDate: Date;
}

export class AdminDto extends EBaseDto {
    adminCode: string;
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    image: string;
    imageLink: string;
    adminStatus: string;
    role: RoleDto;
    lastOpenDate: Date;
    fullname: string;
}

export class AdminStatusDto extends EBaseDto {
    admin: AdminDto;
    performer: AdminDto;
    name: string;
    reason: string;
}

export class ManufacturingSiteDto extends EBaseDto {
    name: string;
    code: number;
}

export class OAuthPartyDto extends EBaseDto {
    party: string;
    partyId: string;
    email: string;
    name: string;
    token: string;
    profileLink: string;
}

export class PersonDto extends EBaseDto {
    email: string;
    homePhoneNumber: string;
    mobilePhone: string;
    firstname: string;
    lastname: string;
    photo: string;
    birthdate: Date;
    gender: string;
    maritalStatus: string;
    latitude: number;
    longitude: number;
    address: string;
    village: VillageDto;
    fullname: string;
    photoUrl: string;
}

export class RegistrationDto {
    firstname: string;
    lastname: string;
    mobilePhone: string;
    email: string;
    userCode: string;
    fullname: string;
}

export class UserDeviceDto extends EBaseDto {
    fcmToken: string;
    platform: string;
    userAgent: string;
    ipAddress: string;
    deviceId: string;
    latitude: number;
    longitude: number;
    lastOpenDate: Date;
}

export class UserDto extends PersonDto {
    userCode: string;
    password: string;
    role: string;
    agreeTermAndCondition: boolean;
    firstLogin: boolean;
    onGoingModel: number;
    totalInspection: number;
    totalNotPassInspection: number;
    totalExpiredModel: number;
    totalRejectedModel: number;
    userStatus: string;
    verificationStatus: string;
    registration: RegistrationDto;
    installed: boolean;
    deviceCount: number;
    authorization: string;
    registrationToken: string;
    lastOpenDate: Date;
    passwordSet: boolean;
    userRole: UserRoleDto;
    manufacturingSite: ManufacturingSiteDto;
    verified: boolean;
}

export class UserInspectionSummary {
    onGoingModel: number;
    totalModel: number;
}

export class UserStatusDto extends EBaseDto {
    performer: AdminDto;
    name: string;
    reason: string;
}

export class UserMenuDto extends EBaseDto {
}

export class UserRoleDto extends EBaseDto {
    name: string;
}

export class UserRoleMenuDto extends EBaseDto {
}

export interface Serializable {
}

export interface Comparable<T> {
}
