import BasePage from './BasePage';
import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import {changeParam2, changeParam3, isEmpty, navigatePage, parseDate, sortirMap} from '../utils/Utilities';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {IoMdSettings} from 'react-icons/io';
import SearchInput from '../components/SearchInput';
import Global, {DD_MM_YYYY_HH_MM_SS, INSPECTION_STATUS_COLOR} from '../utils/Global';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import SockJsClient from 'react-stomp';
import label from '../message/Label'
import {FiArrowDownCircle, FiArrowUpCircle} from "react-icons/fi";
import DateInput from "../components/Widget/DateInput";
import ItemOption from "../components/Widget/ItemOption";
import {MdRestore} from "react-icons/md";
import {FaFileExcel} from "react-icons/fa";
import Page from "../components/Page";
import ModelModal from "../components/modal/ModelModal";


class InspectionPage extends BasePage{

    constructor(props) {
        super(props);
        this.state ={
            ascending:false,
            sortir:'ja.updated',
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            key:queryString.parse(this.props.query).tab?queryString.parse(this.props.query).tab:label.All,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            createdStartDate:null,
            createdEndDate:null,
            updatedStartDate:null,
            updatedEndDate:null,
            inspectionStatuses:[],
            inspections:[],
            models:[],
            model:{},
            modelModal:false,
            inspectionStatus:null,
            inspectionStatusId:null,
            inputSearch:queryString.parse(this.props.query).search?queryString.parse(this.props.query).search:"",
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchAllStatus()
        this.fetchModels()
    }

    componentWillReceiveProps(props, nextContext) {
        if (props != this.props) {
            let page = queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
            let key = queryString.parse(props.query).tab ? queryString.parse(props.query).tab : label.All
            let inputSearch = queryString.parse(props.query).search ? queryString.parse(props.query).search : ""
            let inspectionStatuses = this.state.inspectionStatuses?this.state.inspectionStatuses:[]
            let inspectionStatusId = null
            if (key === label.All) {
                inspectionStatusId = null
            } else {
                inspectionStatuses.map((inspectionStatus)=>{
                    if (key == inspectionStatus.sortir) {
                        inspectionStatusId = inspectionStatus.id
                    }
                })
            }
            this.setState({
                page: page,
                key: key,
                inputSearch: inputSearch,
                inspectionStatusId: inspectionStatusId
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
            })
        }
    }
    fetchAllStatus = () => {
        let {key} = this.state
        this.get(Global.API.INSPECTION_STATUSES, null, null, response => {
            if (response.code === 200) {
                let inspectionStatuses = response.data
                this.fetchBadgeCount(undefined)
                inspectionStatuses.forEach((item, index) => {
                    this.fetchBadgeCount(item.id)
                })
                let inspectionStatusId = null
                inspectionStatuses.map((inspectionStatus)=>{
                    if (key == inspectionStatus.sortir) {
                        inspectionStatusId = inspectionStatus.id
                    }
                })
                this.setState({
                    inspectionStatusId:inspectionStatusId,
                    inspectionStatuses: inspectionStatuses
                }, () => {
                    this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
                })
            }
        }, true, true)
    }

    fetchModels = () =>{
        this.get(Global.API.MODELS, null, null, response=>{
            if(response.code===200){
                this.setState({
                    models : response.data
                })
            }
        }, true, true)
    }

    fetchAll = (ascending, sortir, search, progressing, excelMode) =>{
        let inspectionStatusId = this.state.inspectionStatusId
        let params = {
            inspectionStatusId:inspectionStatusId,
            ascending:ascending,
            sortir:sortir,
            search:search,
            page:this.state.page-1,
            createdStartDate:this.state.createdStartDate,
            createdEndDate:this.state.createdEndDate,
            updatedStartDate:this.state.updatedStartDate,
            updatedEndDate:this.state.updatedEndDate,
            modelId:this.state.model.id,
            excelMode:excelMode
        }
        if(excelMode){
            this.asyncDownloadGet(Global.API.INSPECTIONS, {params:params}, null,true);
            return
        }
        this.get(Global.API.INSPECTIONS, {params:params}, null, res =>{
            if(res.code === 200){
                this.setState({
                    inspections : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    ['size-' + inspectionStatusId ? inspectionStatusId : "all"]: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        }, progressing, true);
    }

    refreshInspections = (ascending, sortir, progressing) => (
        this.setState({
            ascending:ascending,
            sortir:sortir,
        }, () => {
            this.fetchAll(ascending, sortir, this.state.inputSearch, progressing)
        })
    )

    resetFilter(){
        this.setState({
            page :1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            createdStartDate:null,
            createdEndDate:null,
            updatedStartDate:null,
            updatedEndDate:null,
            inspections:[],
            inspectionStatus:null,
            inspectionStatusId:null,
            model:{},
            inputSearch : "",
        }, () => {
            // navigatePage(this.props, 1)
            changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
        })
    }



    render() {
        let totalElement = this.state.totalElement
        let totalPage = this.state.totalPage
        let {models, model, modelModal} = this.state

        return (
            <Page
                title={label.InspectionList}
                breadcrumbs={[{ name: label.InspectionList, active: true }]}
                className="TablePage"
            >
                {super.render()}
                <ModelModal
                    show={modelModal}
                    model={model}
                    onClickOk={()=>{
                        this.setState({modelModal:false})
                    }}/>
                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader style={{cursor:'pointer'}} onClick={event=>{
                                this.setState({
                                    userFilterExpand:!this.state.userFilterExpand
                                })
                            }}>
                                {
                                    (this.state.userFilterExpand)?(<FiArrowUpCircle/>):((<FiArrowDownCircle/>))
                                }
                                &nbsp;{label.AdvanceFilter} ({label.ClickToExpandFilterContent})
                            </CardHeader>
                            <Collapse isOpen={this.state.userFilterExpand}>
                                <CardHeader>
                                    <Row>
                                        <Col md={3}>
                                            <Label for="createdStartDate">{label.CreatedStartDate}</Label>
                                            <DateInput
                                                id="createdStartDate"
                                                value={this.state.createdStartDate}
                                                maxdate={this.state.createdEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdStartDate : value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="createdEndDate">{label.CreatedEndDate}</Label>
                                            <DateInput
                                                id="createdEndDate"
                                                mindate={this.state.createdStartDate}
                                                value={this.state.createdEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdEndDate :  value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="updatedStartDate">{label.UpdatedStartDate}</Label>
                                            <DateInput
                                                id="updatedStartDate"
                                                value={this.state.updatedStartDate}
                                                maxdate={this.state.updatedEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        updatedStartDate : value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="updatedEndDate">{label.UpdatedEndDate}</Label>
                                            <DateInput
                                                id="updatedEndDate"
                                                mindate={this.state.updatedStartDate}
                                                value={this.state.updatedEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        updatedEndDate :  value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.SelectModel}
                                                    objects={models}
                                                    default={!isEmpty(model)?model.id:null}
                                                    callback={(model)=>{
                                                        this.setState({
                                                            model:model
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>{label.Searching}</Label>
                                                <SearchInput
                                                    placeholder={label.SearchReferenceSerialModelUser}
                                                    value={this.state.inputSearch}
                                                    onChange={e=>{
                                                        this.setState({
                                                            inputSearch:e.target.value
                                                        }, () => {
                                                            if(this.state.inputSearch===''){
                                                                changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
                                                            }
                                                        })
                                                    }}
                                                    onEnter={e=>{
                                                        if(this.state.inputSearch!==''){
                                                            changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalRecord}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color:'green'}}><strong>{totalElement}</strong></h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalPage}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color:'blue'}}>{totalPage}</h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={6}>
                                                    <Button
                                                        block
                                                        className={"float-left"}
                                                        color={"primary"}
                                                            onClick={event => {
                                                                navigatePage(this.props, 1)
                                                            }}>
                                                        <IoMdSettings/>&nbsp;{label.ApplyFilter}
                                                    </Button>
                                                </Col>
                                                <Col md={6}>
                                                    <Button block className={"float-left"} color={"yellow"}
                                                            onClick={event => {
                                                                this.resetFilter()
                                                            }}><MdRestore/>&nbsp;{label.ResetFilter}</Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <Button block className={"float-left"} color={"green"}
                                                            onClick={event => {
                                                                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true, true)
                                                            }}><FaFileExcel/>&nbsp;{label.DownloadExcel}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
                {this.renderInspection()}
            </Page>
        )
    }

    fetchBadgeCount(inspectionStatusId) {
        let params = {
            inspectionStatusId: inspectionStatusId,
            ascending: this.state.ascending,
            sortir: this.state.sortir,
            search: this.state.search,
            page: this.state.page - 1,
            createdStartDate: this.state.createdStartDate,
            createdEndDate: this.state.createdEndDate,
            updatedStartDate: this.state.updatedStartDate,
            updatedEndDate: this.state.updatedEndDate,
            modelId: this.state.model.id,
            excelMode: false,
            badgeCountMode:true
        }
        this.get(Global.API.INSPECTIONS, {params: params}, null, (response) => {
            if (response && response.code == 200) {
                this.setState({
                    ['size-' + inspectionStatusId ? inspectionStatusId : "all"]: response.totalElement
                })
            }
        }, false, false);
    }


    badgeTabLabel(label, inspectionStatusId, color) {
        let size = this.state['size-' + inspectionStatusId ? inspectionStatusId : "all"];
        if (!size || size == 0) {
            return (<span>{label}</span>)
        }
        return (
            <>
                <span>{label}</span>
                &nbsp;&nbsp;
                <span
                    style={{
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        paddingLeft: '6px',
                        paddingRight: '6px',
                        background: color ? color : 'red',
                        color: 'white',
                        borderRadius: '20%',
                        minWidth: '24px',
                        display: 'inline-block',
                        textAlign: 'center'
                    }}>
                    {size}
                </span>
            </>)
    }

    renderInspection = () =>{
        return (
            <Row key={2}>
                <Col>
                    <Card>
                        <CardHeader>
                            <strong>{label.Inspection}</strong>
                            <br/><br/>
                            <Row>
                                <Col md={3}>
                                    {label.SortBy} :
                                    <UncontrolledButtonDropdown key={1}>
                                        <DropdownToggle
                                            caret
                                            color="white"
                                            className="text-capitalize m-1">
                                            {
                                                sortirMap(this.state.sortir.toString())
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu style={{transform:'none'}}>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "ja.reference", true))}>{label.Reference}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "ja.serialCode", true))}>{label.SerialCode}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "jm.systemType", true))}>{label.SystemType}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "ja.performerName", true))}>{label.PerformedBy}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "ja.created", true))}>{label.Created}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "ja.updated", true))}>{label.Updated}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                                <Col md={3}>
                                    {label.Sortir} :
                                    <UncontrolledButtonDropdown key={2}>
                                        <DropdownToggle
                                            caret
                                            color="white"
                                            className="text-capitalize m-1">
                                            {
                                                this.state.ascending?label.Ascending:label.Descending
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={e=>(this.refreshInspections(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Tabs
                                activeKey={this.state.key}
                                onSelect={key =>{
                                    changeParam2(this.props, 'page', 1, 'tab', key)
                                }
                                }>
                                <Tab eventKey={label.All}
                                     title={this.badgeTabLabel(label.All, undefined, INSPECTION_STATUS_COLOR['ALL'])}>
                                    {this.renderInspectionTable()}
                                </Tab>
                                {
                                    this.state.inspectionStatuses.map((item, index)=>(
                                        <Tab key={index} eventKey={item.id}
                                             title={this.badgeTabLabel(item.label, item.id, INSPECTION_STATUS_COLOR[item.name])}>
                                            {this.renderInspectionTable()}
                                        </Tab>
                                    ))
                                }
                            </Tabs>
                        </CardBody>
                        <SockJsClient
                            url={Global.BASE_URL}
                            topics={[Global.API.LISTEN_UPDATE_INSPECTIONS]}
                            getRetryInterval={()=>10000}
                            autoReconnect={false}
                            heartbeat={60000}
                            onMessage={(message) => {
                                this.successToast(message)
                                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
                            }}
                            ref={ (client) => { this.clientRef = client }} />
                    </Card>
                </Col>
            </Row>
        )
    }

    renderInspectionTable = () =>{
        return (
            <Card>
                <CardBody>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.ReferenceCode}</th>
                            <th>{label.SerialCode}</th>
                            <th style={{width:'20%'}}>{label.Customer}</th>
                            <th>{label.Model}</th>
                            <th>{label.Category}</th>
                            <th>{label.Status}</th>
                            <th>{label.PerformedBy}</th>
                            <th>{label.Created}</th>
                            <th>{label.Updated}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.inspections.map((item, index)=>(
                                <tr key={item.id}>
                                    <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                    <td><a href={'/inspectionDetail?id='+item.id}>{item.reference}</a></td>
                                    <td><a href={'/inspectionDetail?id='+item.id}>{item.serialCode}</a></td>
                                    <td>
                                        {label.OrderNo} : {item.customerOrderNo}<br/>
                                        {label.Client} : {item.client}<br/>
                                        {label.ProductSKU} : {item.productSKU}<br/>
                                        {label.ProductName} : {item.productName}<br/>
                                    </td>
                                    <td>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({
                                                    model:item.model,
                                                    modelModal:true
                                                })
                                            }}>
                                            {item.model.systemType}
                                        </a>
                                    </td>
                                    <td>
                                        {item.model.modelCategory.name}
                                    </td>
                                    <td>{item.inspectionStatus.label}</td>
                                    <td>
                                        {
                                            item.user?(
                                                    <a href={'/userDetail?id='+item.user.id}>{item.performerName}&nbsp;-&nbsp;{item.performerRole}</a>
                                                ):
                                                (<span>{item.performerName}&nbsp;-&nbsp;{item.performerRole}</span>)
                                        }
                                    </td>
                                    <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                    <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </CardBody>
                <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />
            </Card>
        )
    }
}
export default Object.assign(InspectionPage, {ENDPOINT : "/inspections"})