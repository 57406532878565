import AuthForm, {STATE_LOGIN} from '../components/AuthForm';
import React from 'react';
import {Card, Col, Form, Row,
    CardBody,
    Button,
    CardHeader,CardFooter} from 'reactstrap';
import queryString from "query-string";
import BaseComponent from "../components/BaseComponent";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import ReactSpeedometer from "react-d3-speedometer"
import "react-internet-meter/dist/index.css";
import label from "../message/Label"
import {COPYRIGHT} from "../Variable";
import {Link} from "react-router-dom";
import SockJsClient from "react-stomp";
import Global from "../utils/Global";
import {isSuccess} from "../utils/Utilities";
import BasePage from "./BasePage";

class TokenPage extends BasePage {
    clientTokenRef ;

    constructor(props) {
        super(props);
        let state = this.state
        state.token = {}
        state.netSpeed = 0
        this.setState(state)
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchToken()
    }

    async fetchToken(){
        let response = await this.asyncGet(Global.API.GET_LATEST_TOKEN, null, null, true, true);
        if(isSuccess(response)){
            let token = response.data
            this.setState({token})
        }
    }
    async refreshToken(){
        let response = await this.asyncGet(Global.API.REFRESH_TOKEN, null, null, true, true);
        if(isSuccess(response)){
            let token = response.data
            this.setState({token})
        }
    }


    render() {
        let {token} = this.state
        return (
            <span>
            {super.render()}
            <Row
                style={{
                    height: '130vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Col md={6} lg={4}>
                    <Card style={{textAlignment:'center'}}>
                        <CardHeader>{label.TokenCode}</CardHeader>
                        <CardBody style={{textAlign:'center'}}>
                            <Row>
                                <Col>
                                    <h1 style={{fontSize:'5rem', letterSpacing:'10px', color:"green", fontWeight:"bold"}}>{token.code}</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        {label.TokenDescription}
                                    </p>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    {label.TimeExpired}<br/><font style={{fontSize:'3rem', color:"red"}}>{token.secondRemaining}</font>s
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                            <Button
                                color={"secondary"}
                                size="lg"
                                block
                                onClick={()=>{
                                    this.refreshToken()
                                }}>
                                {label.Refresh}
                            </Button>
                                </Col>
                            </Row>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
                  <SockJsClient
                      url={Global.BASE_URL}
                      topics={[Global.API.LISTEN_UPDATE_TOKEN]}
                      getRetryInterval={()=>10000}
                      heartbeat={1000}
                      autoReconnect={false}
                      onMessage={(message) => {
                          console.log(message)
                          this.setState({token:message})
                      }}
                      heartbeat={1000}
                      ref={ (client) => { this.clientTokenRef = client }} />
            </span>

        );
    }
}

export default Object.assign(TokenPage, {ENDPOINT : "/token"})
